import { Box, BoxProps, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import React, { useEffect, VFC } from 'react';
import { useCountdown } from '../../hooks/use-countdown';

export const Countdown = ({ ...rest }: BoxProps) => {
    const { hours, minutes, seconds } = useCountdown();

    useEffect(() => {
        if (hours === '00' && minutes === '00' && seconds === '00') {
            window.location.reload();
        }
    }, [hours, minutes, seconds]);

    return (
        <Box fontSize="2xl" fontWeight="semibold" {...rest}>
            <Text>Next Blurdle in:</Text>
            <Text>
                {hours}:{minutes}:{seconds}
            </Text>
            <Tooltip
                textAlign="center"
                fontSize="xs"
                label="Blurdle resets at midnight UTC, so this may vary depending on your timezone."
            >
                <Text mt="2" fontSize="xs" cursor="pointer">
                    How is this calculated?
                </Text>
            </Tooltip>
        </Box>
    );
};
