import { Box } from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FC } from 'react';
import { createContext } from 'react';
import { useDebounce } from 'react-use';

import { useSeededState } from '../../hooks/use-seeded-state';
import { useStatsProvider } from '../stats-provider';

export type Step = 6 | 5 | 4 | 3 | 2 | 1 | 0;

type GameContextOptions = {
    blurdleNumber: number;
    isSolved: boolean;
    isFailed: boolean;
    isFinished: boolean;
    stepsLeft: Step;
    incorrectGuesses: string[];
    userGuesses: number;
    correctAnswer: string;
    onSubmitGuess: (guess: string) => void;
};

export const GameContext = createContext<GameContextOptions>({
    blurdleNumber: 0,
    stepsLeft: 6,
    userGuesses: 0,
    isSolved: false,
    isFailed: false,
    isFinished: false,
    incorrectGuesses: [],
    correctAnswer: '',
    onSubmitGuess: () => {},
});

type GameProviderProps = {
    correctAnswer: string;
    blurdleNumber: number;
};

export const GameProvider: FC<React.PropsWithChildren & GameProviderProps> = ({
    children,
    correctAnswer,
    blurdleNumber,
}) => {
    const { addEndOfGameStats } = useStatsProvider();
    const [isLoaded, setIsLoaded] = useState(false);
    const [stepsLeft, setStepsLeft] = useSeededState<number>('steps-left', 6);
    const [incorrectGuesses, setIncorrectGuesses] = useSeededState<string[]>('incorrect-guesses', []);
    const [isSolved, setIsSolved] = useSeededState<boolean>('is-solved', false);
    const [isFailed, setIsFailed] = useSeededState<boolean>('is-failed', false);

    const userGuesses = 6 - (6 - (incorrectGuesses?.length ?? 0) - 1);

    /**
     * Called when a guess is being submitted. If correct, the solved state is active, otherwise the game continues with a guess added.
     */
    const handleSubmitGuess = useCallback(
        (guess: string) => {
            if (!stepsLeft || !incorrectGuesses) return;

            if (guess === correctAnswer) {
                setIsSolved(true);
            } else {
                setStepsLeft(stepsLeft - 1);
                setIncorrectGuesses([...incorrectGuesses, guess]);
            }
        },
        [correctAnswer, incorrectGuesses, stepsLeft, setIsSolved, setStepsLeft, setIncorrectGuesses]
    );

    /**
     * Called when the steps left of the game reaches 0, setting the failed state.
     */
    useEffect(() => {
        if ((stepsLeft as number) <= 0) {
            setIsFailed(true);
        }
    }, [stepsLeft, setIsFailed]);

    /**
     * Tracks the user's stats when a finish state occurs (success or failure).
     */
    useEffect(() => {
        if ((isSolved || isFailed) && isLoaded) {
            addEndOfGameStats(userGuesses as 1 | 2 | 3 | 4 | 5 | 6);
        }
    }, [addEndOfGameStats, userGuesses, isSolved, isFailed, isLoaded]);

    const value = {
        blurdleNumber,
        isFailed: !!isFailed,
        isSolved: !!isSolved,
        isFinished: !!isFailed || !!isSolved,
        onSubmitGuess: handleSubmitGuess,
        incorrectGuesses: incorrectGuesses,
        correctAnswer,
        userGuesses,
        stepsLeft: stepsLeft,
    } as GameContextOptions;

    /**
     * Debounces the loading state to only display once all values have settled. Required since all useRedundantState hooks need to rehydrate before the game can start.
     */
    useDebounce(() => setIsLoaded(true), 500, Object.values(value));

    return (
        <GameContext.Provider value={value}>
            <Box h="full" opacity={isLoaded ? 1 : 0} transition="0.2s opacity ease-in-out">
                {children}
            </Box>
        </GameContext.Provider>
    );
};

export const useGameProvider = () => useContext(GameContext);
