import {
    Box,
    Flex,
    Grid,
    GridProps,
    Heading,
    HeadingProps,
    Stack,
    StackProps,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { FC } from 'react';

type AchievementProps = StackProps & {
    variant: 'completed' | 'incomplete';
    special?: boolean;
};

export const Achievement: FC<React.PropsWithChildren & AchievementProps> = ({
    children,
    variant,
    special,
    ...rest
}) => {
    return (
        <Stack
            as="article"
            p="3"
            gap="2"
            borderRadius="lg"
            borderColor={variant === 'completed' ? 'yellow.400' : 'white'}
            background={variant === 'completed' ? 'yellow.100' : 'transparent'}
            color={variant === 'completed' ? 'yellow.700' : 'white'}
            filter={special === true ? 'blur(0.5rem)' : undefined}
            draggable="false"
            userSelect="none"
            {...rest}
        >
            {children}
        </Stack>
    );
};
export const AchievementContent: FC<GridProps> = ({ children, ...rest }) => {
    return (
        <Grid gridTemplateColumns="32px 1fr" gap="12" {...rest}>
            {children}
        </Grid>
    );
};

export const AchievementValue: FC<TextProps> = ({ children, ...rest }) => {
    return (
        <Flex w="16" h="16" border="2px" borderRadius="full" justifyContent="center" alignItems="center">
            <Box>
                <Text fontWeight="bold" fontSize="xl" textAlign="center" {...rest}>
                    {children}
                </Text>
            </Box>
        </Flex>
    );
};

export const AchievementBody: FC<StackProps> = ({ children, ...rest }) => {
    return (
        <Stack textAlign="center" gap="0" my="auto" {...rest}>
            {children}
        </Stack>
    );
};

export const AchievementTitle: FC<HeadingProps> = ({ children, ...rest }) => {
    return (
        <Heading as="h2" fontSize="xl" {...rest}>
            {children}
        </Heading>
    );
};

export const AchievementDescription: FC<TextProps> = ({ children, ...rest }) => {
    return (
        <Text fontSize="sm" {...rest}>
            {children}
        </Text>
    );
};

export const AchievementDate: FC<TextProps> = ({ children, ...rest }) => {
    return (
        <Text fontSize="xs" {...rest}>
            {children}
        </Text>
    );
};
