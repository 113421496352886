export type CumulativeCondition = {
    conditionType: 'cumulative';
    type: 'win' | 'loss' | 'plays';
    value: number;
};

export type WinScoreThresholdCondition = {
    conditionType: 'winScoreThreshold';
    score: number;
    value: number;
};

export type ScoreCondition = {
    conditionType: 'score';
    value: number;
};

export type DateCondition = {
    conditionType: 'date';
    date: Date;
};

export type DateRangeCondition = {
    conditionType: 'dateRange';
    daysInRange: number;
};

export type Condition =
    | CumulativeCondition
    | WinScoreThresholdCondition
    | ScoreCondition
    | DateCondition
    | DateRangeCondition;

export type Achievement = {
    id: string;
    name: string;
    description: string;
    value: number;
    special: boolean;
    condition: Condition;
};

const scoreAchievements: Achievement[] = [
    {
        id: 'score-achievement-1',
        name: 'Welcome to Blurdle!',
        description: 'Won your first game.',
        value: 1,
        special: false,
        condition: {
            conditionType: 'cumulative',
            type: 'win',
            value: 1,
        },
    },
    {
        id: 'score-achievement-2',
        name: "You're a pro!",
        description: 'Won 10 games.',
        value: 5,
        special: false,
        condition: {
            conditionType: 'cumulative',
            type: 'win',
            value: 10,
        },
    },
    {
        id: 'score-achievement-3',
        name: "You're a legend!",
        description: 'Won 25 games.',
        value: 10,
        special: false,
        condition: {
            conditionType: 'cumulative',
            type: 'win',
            value: 25,
        },
    },
    {
        id: 'score-achievement-4',
        name: "You're a champion!",
        description: 'Won 50 games.',
        value: 20,
        special: false,
        condition: {
            conditionType: 'cumulative',
            type: 'win',
            value: 50,
        },
    },
    {
        id: 'score-achievement-5',
        name: 'Blurdle Master',
        description: 'Won 100 games.',
        value: 50,
        special: false,
        condition: {
            conditionType: 'cumulative',
            type: 'win',
            value: 100,
        },
    },
    {
        id: 'score-achievement-6',
        name: "Can't win 'em all",
        description: 'Lost a game.',
        value: 1,
        special: true,
        condition: {
            conditionType: 'cumulative',
            type: 'loss',
            value: 1,
        },
    },
    {
        id: 'score-achievement-7',
        name: 'Are you okay?',
        description: 'Lost 10 games.',
        value: 10,
        special: true,
        condition: {
            conditionType: 'cumulative',
            type: 'loss',
            value: 10,
        },
    },
    {
        id: 'score-achievement-8',
        name: 'First timer',
        description: 'Played your first game.',
        value: 1,
        special: false,
        condition: {
            conditionType: 'cumulative',
            type: 'plays',
            value: 1,
        },
    },
];

const thresholdAchievements: Achievement[] = [
    {
        id: 'threshold-achievement-1',
        name: 'On the edge',
        description: 'Finished a game on your last guess.',
        value: 5,
        special: true,
        condition: {
            conditionType: 'score',
            value: 6,
        },
    },
    {
        id: 'threshold-achievement-2',
        name: 'Close one',
        description: 'Finished a game with 1 guess remaining.',
        value: 5,
        special: true,
        condition: {
            conditionType: 'score',
            value: 5,
        },
    },
    {
        id: 'threshold-achievement-3',
        name: 'In the bag',
        description: 'Finished a game with 2 guesses remaining.',
        value: 5,
        special: true,
        condition: {
            conditionType: 'score',
            value: 4,
        },
    },
    {
        id: 'threshold-achievement-4',
        name: 'Even split',
        description: 'Guessed the daily image with 3 guesses remaining.',
        value: 5,
        special: true,
        condition: {
            conditionType: 'score',
            value: 3,
        },
    },
    {
        id: 'threshold-achievement-5',
        name: 'Double trouble',
        description: 'Guessed the daily image in two guesses.',
        value: 5,
        special: true,
        condition: {
            conditionType: 'score',
            value: 2,
        },
    },
    {
        id: 'threshold-achievement-6',
        name: 'Are you cheating?',
        description: 'Guessed the daily image in a single guess.',
        value: 20,
        special: true,
        condition: {
            conditionType: 'score',
            value: 1,
        },
    },
];

const dateRangeAchievements: Achievement[] = [
    {
        id: 'date-range-achievement-1',
        name: 'Back-to-back',
        description: 'Played 2 games in 2 days.',
        value: 5,
        special: false,
        condition: {
            conditionType: 'dateRange',
            daysInRange: 2,
        },
    },
    {
        id: 'date-range-achievement-2',
        name: 'Back-to-back-to-back',
        description: 'Played 3 games in 3 days.',
        value: 5,
        special: false,
        condition: {
            conditionType: 'dateRange',
            daysInRange: 3,
        },
    },
    {
        id: 'date-range-achievement-3',
        name: 'Not for the week',
        description: 'Played 7 days in a row.',
        value: 10,
        special: false,
        condition: {
            conditionType: 'dateRange',
            daysInRange: 7,
        },
    },
    {
        id: 'date-range-achievement-4',
        name: 'Week 2: Electric Boogaloo',
        description: 'Played 14 days in a row.',
        value: 20,
        special: false,
        condition: {
            conditionType: 'dateRange',
            daysInRange: 14,
        },
    },
    {
        id: 'date-range-achievement-5',
        name: 'Monthly Marathon',
        description: 'Played an entire month, back-to-back.',
        value: 50,
        special: false,
        condition: {
            conditionType: 'dateRange',
            daysInRange: 30,
        },
    },
];

const dateAchievements: Achievement[] = [
    {
        id: 'date-achievement-1',
        name: 'Happy Birthday!',
        description: "Played on the developer's birthday.",
        value: 1,
        special: true,
        condition: {
            conditionType: 'date',
            date: new Date('2000-07-20 23:59:59'),
        },
    },
    {
        id: 'date-achievement-2',
        name: 'Merry Christmas!',
        description: 'Played on Christmas Day.',
        value: 1,
        special: true,
        condition: {
            conditionType: 'date',
            date: new Date('2000-12-25 23:59:59'),
        },
    },
    {
        id: 'date-achievement-3',
        name: 'Spooky!',
        description: 'Played on Halloween.',
        value: 5,
        special: true,
        condition: {
            conditionType: 'date',
            date: new Date('2000-10-31 23:59:59'),
        },
    },
    {
        id: 'date-achievement-4',
        name: 'Happy New Year!',
        description: "Played on New Year's Day.",
        value: 5,
        special: true,
        condition: {
            conditionType: 'date',
            date: new Date('2000-01-01 23:59:59'),
        },
    },
    {
        id: 'date-achievement-5',
        name: 'Remember remember...',
        description: 'Played on Guy Fawkes Night.',
        value: 5,
        special: true,
        condition: {
            conditionType: 'date',
            date: new Date('2000-11-05 23:59:59'),
        },
    },
];

export const achievements: Achievement[] = [
    ...scoreAchievements,
    ...thresholdAchievements,
    ...dateRangeAchievements,
    ...dateAchievements,
];
