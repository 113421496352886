import { useCallback, useEffect, useState } from 'react';
import localforage from 'localforage';

/**
 * Provides a redundant version of useState. The retuning value can be undefined if queried before localforage's async fetch has returned anything.
 * @param key Key to store the state under.
 * @param initialValue Initial value of the state.
 * @returns
 */
export const useRedundantState = <T extends any>(
    key: string,
    initialValue: T | undefined = undefined
): [T | undefined, (value: T) => void] => {
    const [value, setValue] = useState<T>();

    const handleState = useCallback(
        (state: T) => {
            setValue(state);
            localforage.setItem(key, state);
        },
        [key, setValue]
    );

    useEffect(() => {
        const getStorage = async () => {
            const val = await localforage.getItem<T>(key);

            if (val) {
                setValue(val);
            } else {
                setValue(initialValue);
            }
        };

        getStorage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    return [value, handleState];
};
