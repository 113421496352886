import { useCallback, useEffect, useMemo, useState } from 'react';
import { BlurdleAsset } from '../../pages';
import { useGameProvider } from '../../providers/game-provider';

export type Option = {
    value: string;
    label: string;
};

export type Group = {
    label: string;
    options: Option[];
};

export const useAnswerSubmission = (possibleAnswers: BlurdleAsset[]) => {
    const { incorrectGuesses, onSubmitGuess } = useGameProvider();
    const [selectedAnswer, setSelectedAnswer] = useState<Option | null>(null);

    useEffect(() => setSelectedAnswer(null), [incorrectGuesses]);

    const options = useMemo<Group[]>(() => {
        if (!incorrectGuesses) {
            return [];
        }

        const groups: Group[] = [];
        const unguessedAnswers = possibleAnswers.filter((answer) => !incorrectGuesses.includes(answer.name));

        unguessedAnswers.forEach((g) => {
            const matchingGroup = groups.find((m) => m.label === g.category);

            if (!matchingGroup) {
                groups.push({ label: g.category, options: [{ value: g.name, label: g.name }] });
                return;
            }

            matchingGroup.options.push({ label: g.name, value: g.name });
        });

        return groups;
    }, [possibleAnswers, incorrectGuesses]);

    const handleSubmit = useCallback(() => {
        if (!selectedAnswer) {
            return;
        }

        onSubmitGuess(selectedAnswer.value);
    }, [selectedAnswer, onSubmitGuess]);

    return {
        options,
        selectedAnswer,
        setSelectedAnswer,
        submitAnswer: handleSubmit,
    };
};
