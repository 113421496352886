import dayjs from 'dayjs';
import { CompletedAchievement } from '../providers/achievement-provider';
import {
    Achievement,
    CumulativeCondition,
    ScoreCondition,
    DateCondition,
    DateRangeCondition,
    WinScoreThresholdCondition,
    Condition,
} from '../providers/achievement-provider/achievements';
import { Stats } from '../providers/stats-provider';
import { getDateRange, getUTC } from './time';

class ConditionValidator {
    private stats: Stats;

    constructor(stats: Stats) {
        this.stats = stats;
    }

    isCumulativeConditionMet(condition: CumulativeCondition): boolean {
        switch (condition.type) {
            case 'plays':
                return this.stats.total >= condition.value;
            case 'win':
                return this.stats.won >= condition.value;
            case 'loss':
                return this.stats.failed >= condition.value;
            default:
                return false;
        }
    }

    isCumulativeScoreConditionMet(condition: WinScoreThresholdCondition): boolean {
        const matchingEntries = this.stats.datesPlayed.filter((d) => d.numberOfGuesses <= condition.score);

        return matchingEntries.length >= condition.value;
    }

    isScoreConditionMet(condition: ScoreCondition): boolean {
        const matchingEntry = this.stats.datesPlayed.find((d) => d.numberOfGuesses === condition.value);

        return !!matchingEntry;
    }

    isDateConditionMet(condition: DateCondition): boolean {
        const matchingEntry = this.stats.datesPlayed.find(
            (d) => dayjs(d.date).format('MM-DD') === dayjs(condition.date).format('MM-DD')
        );

        return !!matchingEntry;
    }

    isDateRangeConditionMet(condition: DateRangeCondition): boolean {
        let isConditionMet = false;
        let daysInRow = 0;

        for (let i = 0; i <= this.stats.datesPlayed.length; i++) {
            const currentDate = this.stats.datesPlayed[i]?.date;
            const nextDate = this.stats.datesPlayed[i + 1]?.date;

            if (dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD') === dayjs(nextDate).format('YYYY-MM-DD')) {
                daysInRow += 1;
            } else {
                daysInRow = 0;
            }

            if (daysInRow >= condition.daysInRange) {
                isConditionMet = true;
                break;
            }
        }

        return isConditionMet;
    }

    isConditionMet(condition: Condition): boolean {
        let isConditionMet = true;

        switch (condition.conditionType) {
            case 'cumulative':
                if (!this.isCumulativeConditionMet(condition)) {
                    isConditionMet = false;
                }
                break;
            case 'winScoreThreshold':
                if (!this.isCumulativeScoreConditionMet(condition)) {
                    isConditionMet = false;
                }
                break;
            case 'score':
                if (!this.isScoreConditionMet(condition)) {
                    isConditionMet = false;
                }
                break;
            case 'date':
                if (!this.isDateConditionMet(condition)) {
                    isConditionMet = false;
                }
                break;
            case 'dateRange':
                if (!this.isDateRangeConditionMet(condition)) {
                    isConditionMet = false;
                }
                break;
            default:
                break;
        }

        return isConditionMet;
    }
}

const setAchievementCompleted = (achievement: Achievement): CompletedAchievement => {
    const date = getUTC().toDate();

    return { ...achievement, dateCompleted: date };
};

export const getNewlyCompletedAchievements = (
    incompleteAchievements: Achievement[],
    stats: Stats
): CompletedAchievement[] => {
    const completed: CompletedAchievement[] = [];
    const validator = new ConditionValidator(stats);

    incompleteAchievements.forEach((achievement) => {
        if (validator.isConditionMet(achievement.condition)) {
            completed.push(setAchievementCompleted(achievement));
        }
    });

    return completed;
};
