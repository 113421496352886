import {
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Spacer,
  Text,
  Box,
  Divider,
  Flex,
  ModalProps,
} from "@chakra-ui/react";
import React, { VFC } from "react";

import { useShareProgress } from "../../../hooks/use-share-progress";
import { useGameProvider } from "../../../providers/game-provider";
import { useModalProvider } from "../../../providers/modal-provider/ModelProvider";
import { Countdown } from "../../countdown/Countdown";

export const SuccessModal = ({ isOpen, onClose }: Omit<ModalProps, 'children'>) => {
    const { openModal } = useModalProvider();
    const { isShareAvailable, share } = useShareProgress();
    const { correctAnswer, userGuesses } = useGameProvider();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="#121212" textAlign="center">
                <ModalHeader>Solved!</ModalHeader>

                <ModalBody bg="">
                    <Stack>
                        <Text>
                            You guessed it, the answer is <b>{correctAnswer}</b>.
                        </Text>
                        <Text>
                            You solved it in <b>{userGuesses}</b> guesses!
                        </Text>
                        <Box>
                            <Divider mt="4" />
                        </Box>
                    </Stack>

                    <Countdown mt="4" />
                    <Divider my="4" />

                    <Stack>
                        <Button onClick={() => openModal('stats')}>Stats</Button>
                        <Button onClick={() => openModal('achievements')}>Achievements</Button>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    <Spacer />
                    <Flex gap="4">
                        {isShareAvailable && (
                            <Button onClick={share} colorScheme="green">
                                Share
                            </Button>
                        )}
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
