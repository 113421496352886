import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Stack,
    Grid,
    Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { VFC } from 'react';
import { useAchievements } from '../../../providers/achievement-provider';
import {
    Achievement,
    AchievementBody,
    AchievementContent,
    AchievementDate,
    AchievementDescription,
    AchievementTitle,
    AchievementValue,
} from '../../achievement';

export const AchievementModal = ({ isOpen, onClose }: Omit<ModalProps, 'children'>) => {
    const { completed, incomplete, achievementPoints } = useAchievements();

    if (!completed || !incomplete) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="xl" bg="#121212" textAlign="center">
                <Stack>
                    <ModalHeader>Achievements ({achievementPoints} points)</ModalHeader>
                </Stack>

                <ModalBody maxH="60vh" overflow="hidden" overflowY="auto">
                    <Stack gap="0" divider={<Divider borderColor="whiteAlpha.500" />}>
                        {completed
                            .sort((a, b) => b.dateCompleted.getTime() - a.dateCompleted.getTime())
                            .map((achievement) => (
                                <Achievement variant="completed" key={achievement.id}>
                                    <AchievementContent>
                                        <AchievementValue>{achievement.value}</AchievementValue>
                                        <AchievementBody>
                                            <AchievementTitle>{achievement.name}</AchievementTitle>
                                            <AchievementDescription>{achievement.description}</AchievementDescription>
                                        </AchievementBody>
                                    </AchievementContent>
                                    <Divider borderColor="yellow.400" />
                                    <AchievementDate>
                                        Earned {dayjs(achievement.dateCompleted).format('DD MMMM YYYY')}
                                    </AchievementDate>
                                </Achievement>
                            ))}
                        {incomplete.map((achievement) => (
                            <Achievement variant="incomplete" special={achievement.special} key={achievement.id}>
                                <AchievementContent>
                                    <AchievementValue>{achievement.value}</AchievementValue>
                                    <AchievementBody>
                                        <AchievementTitle>{achievement.name}</AchievementTitle>
                                        <AchievementDescription>{achievement.description}</AchievementDescription>
                                    </AchievementBody>
                                </AchievementContent>
                            </Achievement>
                        ))}
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
