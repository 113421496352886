import JSConfetti from 'js-confetti';
import { useEffect, useState } from 'react';

export const useConfetti = () => {
    const [confetti, setConfetti] = useState<JSConfetti>();

    useEffect(() => {
        setConfetti(new JSConfetti());
    }, []);

    return confetti;
};
