import { Box } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import {
  Label,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import { useStatsProvider } from "../../../providers/stats-provider";

export const StatsChart: FC = () => {
  const { stats } = useStatsProvider();

  const data = useMemo(() => {
    return Object.keys(stats.summary).map((s) => ({
      name: s,
      //@ts-ignore
      value: stats.summary[s],
    }));
  }, [stats.summary]);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={data} margin={{ left: -15, bottom: 15 }}>
        <XAxis dataKey="name">
          <Label
            offset={-15}
            fill="white"
            value="Number of Guesses"
            position="insideBottom"
          />
        </XAxis>
        <YAxis allowDecimals={false}>
          <Label
            offset={25}
            angle={-90}
            fill="white"
            value="Count"
            position="insideLeft"
          />
        </YAxis>

        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};
