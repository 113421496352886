import { createContext, FC, useContext } from "react";

type SeedContextOptions = React.PropsWithChildren & {
    seed: string;
};

export const SeedContext = createContext<SeedContextOptions>({ seed: "" });

export const SeedProvider: FC<React.PropsWithChildren & SeedContextOptions> = ({ seed, children }) => {
    return <SeedContext.Provider value={{ seed }}>{children}</SeedContext.Provider>;
};

export const useSeed = () => useContext(SeedContext);
