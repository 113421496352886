import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useConfetti } from '../../hooks/use-confetti';
import { useRedundantState } from '../../hooks/use-redundant-state';
import { getUTC } from '../../utils/time';

type PossibleGuesses = 1 | 2 | 3 | 4 | 5 | 6;

export type Stats = {
    summary: {
        [K in PossibleGuesses]: number;
    };
    failed: number;
    won: number;
    total: number;
    datesPlayed: DateStat[];
};

type DateStat = {
    date: string;
    numberOfGuesses: number;
    failed: boolean;
};

type StatsContextOptions = {
    stats: Stats;
    isTodaysStatsLogged: boolean;
    addEndOfGameStats: (guesses: PossibleGuesses) => void;
};

const initialStatState = {
    summary: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
    },
    failed: 0,
    won: 0,
    total: 0,
    datesPlayed: [],
};

const StatsContext = createContext<StatsContextOptions>({
    stats: initialStatState,
    isTodaysStatsLogged: false,
    addEndOfGameStats: () => {},
});

export const StatsProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const confetti = useConfetti();
    const [isTodaysStatsLogged, setIsTodaysStatsLogged] = useState(false);
    const [stats, setStats] = useRedundantState<Stats>('game-stats', initialStatState);

    useEffect(() => {
        const now = getUTC().format('YYYY-MM-DD');

        if (stats && stats.datesPlayed.find((s) => s.date === now)) {
            setIsTodaysStatsLogged(true);
        }
    }, [stats]);

    /**
     * Logs the number of guesses it took to solve the game. Stats are only logged if the game hasn't been played today.
     */
    const addEndOfGameStats = (guesses: PossibleGuesses | 7) => {
        const isFailed = guesses === 7;
        const now = getUTC().format('YYYY-MM-DD');

        if (stats && !isTodaysStatsLogged) {
            const statsCopy = { ...stats };

            statsCopy.datesPlayed.push({
                date: now,
                numberOfGuesses: guesses,
                failed: isFailed,
            });

            if (isFailed) {
                statsCopy.failed += 1;
            } else {
                confetti?.addConfetti();
                const guess = guesses as PossibleGuesses;

                statsCopy.won += 1;
                statsCopy.summary[guess] += 1;
            }

            statsCopy.total += 1;
            setIsTodaysStatsLogged(true);
            setStats(statsCopy);
        }
    };

    if (!stats) {
        return null;
    }

    return (
        <StatsContext.Provider value={{ stats, isTodaysStatsLogged, addEndOfGameStats }}>
            {children}
        </StatsContext.Provider>
    );
};

export const useStatsProvider = () => {
    return useContext(StatsContext);
};
