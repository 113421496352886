import { FC, useEffect, useMemo, useReducer } from 'react';
import { createContext } from '@chakra-ui/react-utils';

import { SuccessModal } from '../../components/modals/success-modal';
import { FailedModal } from '../../components/modals/failed-modal';
import { StatsModal } from '../../components/modals/stats-modal';
import { HelpModal } from '../../components/modals/help-modal';
import { useGameProvider } from '../game-provider';
import { AchievementModal } from '../../components/modals/achievement-modal';

type ModalEventTypes = ModalTypes | 'close';

type ModalTypes = 'success' | 'failed' | 'help' | 'stats' | 'achievements';

type ModalReducerState = {
    [K in ModalTypes]: boolean;
};

const closedState: ModalReducerState = {
    success: false,
    failed: false,
    help: false,
    stats: false,
    achievements: false,
};

const reducer = (state: ModalReducerState, type: ModalEventTypes): ModalReducerState => {
    switch (type) {
        case 'close':
            return closedState;
        case 'success':
            return { ...closedState, success: true };
        case 'failed':
            return { ...closedState, failed: true };
        case 'help':
            return { ...closedState, help: true };
        case 'stats':
            return { ...closedState, stats: true };
        case 'achievements':
            return { ...closedState, achievements: true };
        default:
            return state;
    }
};

type ModalContextOptions = {
    openModal: (type: ModalTypes) => void;
    closeAll: () => void;
};

export const [Provider, useModalProvider] = createContext<ModalContextOptions>();

export const ModalProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const { isSolved, isFailed } = useGameProvider();
    const [state, dispatch] = useReducer(reducer, closedState);

    const isLoaded = useMemo(() => localStorage.getItem('is-initial-help-loaded'), []);

    /**
     * Displays the success modal when the solved state is reached.
     */
    useEffect(() => {
        if (isSolved === true) {
            dispatch('success');
        }
    }, [isSolved]);

    /**
     * Displays the failure modal when the failed state is reached.
     */
    useEffect(() => {
        if (isFailed === true) {
            dispatch('failed');
        }
    }, [isFailed]);

    useEffect(() => {
        if (!isLoaded) {
            dispatch('help');
            localStorage.setItem('is-initial-help-loaded', 'true');
        }
    }, [isLoaded]);

    const closeAll = () => dispatch('close');

    return (
        <Provider value={{ closeAll, openModal: dispatch }}>
            <SuccessModal isOpen={state.success} onClose={closeAll} />
            <FailedModal isOpen={state.failed} onClose={closeAll} />
            <StatsModal isOpen={state.stats} onClose={closeAll} />
            <HelpModal isOpen={state.help} onClose={closeAll} />
            <AchievementModal isOpen={state.achievements} onClose={closeAll} />
            {children}
        </Provider>
    );
};
