import { Box, Container, Flex, Heading, Spacer, IconButton, Icon } from '@chakra-ui/react';
import { FC } from 'react';
import { GoGraph } from 'react-icons/go';
import { IoIosTrophy } from 'react-icons/io';
import { BsQuestion } from 'react-icons/bs';

import { useModalProvider } from '../../providers/modal-provider';
import { useGameProvider } from '../../providers/game-provider';

export const Layout: FC<React.PropsWithChildren> = ({ children }) => {
    const { blurdleNumber } = useGameProvider();
    const { openModal } = useModalProvider();

    return (
        <Flex flexDirection="column" h="100%">
            <Box py="2" dropShadow="lg">
                <Container maxW="container.xl">
                    <Flex gap="2">
                        <Heading userSelect="none">
                            Blurdle{' '}
                            <Box as="span" fontSize="large" color="coral">
                                #{blurdleNumber}
                            </Box>
                        </Heading>
                        <Spacer />
                        <IconButton
                            onClick={() => openModal('achievements')}
                            variant="outline"
                            p="2"
                            borderRadius="full"
                            icon={<Icon h="full" w="full" as={IoIosTrophy} />}
                            aria-label="Achievements"
                            title="Achievements"
                        />
                        <IconButton
                            onClick={() => openModal('stats')}
                            variant="outline"
                            p="2"
                            borderRadius="full"
                            icon={<Icon h="full" w="full" as={GoGraph} />}
                            aria-label="Stats"
                            title="Stats"
                        />
                        <IconButton
                            onClick={() => openModal('help')}
                            variant="outline"
                            p="1"
                            borderRadius="full"
                            icon={<Icon h="full" w="full" as={BsQuestion} />}
                            aria-label="Help"
                            title="Help"
                        />
                    </Flex>
                </Container>
            </Box>

            <Box h="full">
                <Container h="full" as="main" maxW="container.md">
                    <Box h="full">{children}</Box>
                </Container>
            </Box>
        </Flex>
    );
};
