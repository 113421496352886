import {
  Box,
  Button,
  Divider,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { VFC } from "react";

export const HelpModal: VFC<Omit<ModalProps, "children">> = ({
  isOpen,
  onClose,
}) => {
  return (
      <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg="#121212" textAlign="center">
              <ModalHeader>Help</ModalHeader>

              <ModalBody>
                  <Stack>
                      <OrderedList lineHeight={1.75} textAlign="left">
                          <ListItem>
                              Each day, a new <b>Blurdle</b> is available
                          </ListItem>
                          <ListItem>
                              Using the search bar at the bottom, your task is to guess what the <b>Blurdle</b> is, in
                              as few guesses as possible
                          </ListItem>
                          <ListItem>Be careful, you have a max of 6 tries!</ListItem>
                      </OrderedList>

                      <Divider />
                      <Text>Good Luck!</Text>
                      <Text>
                          Built with
                          <Box as="span" mr="2" ml="1">
                              ❤️
                          </Box>
                          by{' '}
                          <Link href="https://adammyoung.com" target="_blank" textDecoration="underline">
                              Adam Young
                          </Link>
                      </Text>
                  </Stack>
              </ModalBody>
              <ModalFooter>
                  <Button onClick={onClose}>Close</Button>
              </ModalFooter>
          </ModalContent>
      </Modal>
  );
};
