import { useBreakpointValue } from '@chakra-ui/media-query';
import { ToastPosition, useToast } from '@chakra-ui/toast';
import React, { FC, createContext, useContext, useEffect, useMemo } from 'react';
import {
    Achievement,
    AchievementBody,
    AchievementContent,
    AchievementDescription,
    AchievementTitle,
    AchievementValue,
} from '../../components/achievement';
import { useRedundantState } from '../../hooks/use-redundant-state';
import { getNewlyCompletedAchievements } from '../../utils/achievements';
import { useStatsProvider } from '../stats-provider';
import { Achievement as AchievementType, achievements } from './achievements';

export type CompletedAchievement = AchievementType & {
    dateCompleted: Date;
};

type CompletionEntry = {
    id: string;
    date: Date;
};

type AchievementContextOptions = {
    achievementPoints: number;
    completed: CompletedAchievement[];
    incomplete: AchievementType[];
};

export const AchievementContext = createContext<AchievementContextOptions>({
    achievementPoints: 0,
    completed: [],
    incomplete: [],
});

export const AchievementProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const toastPosition = useBreakpointValue(['bottom', null, 'bottom-right']);
    const { stats, isTodaysStatsLogged } = useStatsProvider();
    const toast = useToast();

    const [completedEntries, setCompletedEntries] = useRedundantState<CompletionEntry[]>('completed-achievements', []);

    /**
     * All completed achievements saved for the user.
     */
    const completed = useMemo(() => {
        const entries: CompletedAchievement[] = [];

        if (!completedEntries) return;

        completedEntries!.forEach((c) => {
            const achievement = achievements.find((a) => a.id === c.id);

            if (achievement) {
                entries.push({ ...achievement, dateCompleted: new Date(c.date) });
            }
        });

        return entries;
    }, [completedEntries]);

    /**
     * All incomplete achievements for the user.
     */
    const incomplete = useMemo(() => {
        if (completed) {
            return achievements.filter((a) => !completed.some((c) => c.id === a.id));
        }
    }, [completed]);

    const isAchievementSetsLoaded =
        Array.isArray(completedEntries) && Array.isArray(completed) && Array.isArray(incomplete);

    /**
     * Total number of achievement points earned.
     */
    const achievementPoints = useMemo(() => {
        if (isAchievementSetsLoaded) {
            return completed.reduce((prev, curr) => prev + curr.value, 0);
        }

        return 0;
    }, [completed, isAchievementSetsLoaded]);

    /**
     * Effect to trigger when a solve or fail state occurs, to track achievement progress.
     */
    useEffect(() => {
        if (isTodaysStatsLogged && isAchievementSetsLoaded) {
            const newlyCompletedAchievements = getNewlyCompletedAchievements(incomplete, stats);

            if (newlyCompletedAchievements.length > 0) {
                newlyCompletedAchievements.forEach((a) => {
                    toast({
                        variant: 'solid',
                        status: 'success',
                        position: (toastPosition ?? 'bottom') as ToastPosition,
                        duration: 5000,
                        isClosable: true,
                        render: () => (
                            <Achievement variant="completed" userSelect="none" pointerEvents="none">
                                <AchievementContent>
                                    <AchievementValue>{a.value}</AchievementValue>
                                    <AchievementBody>
                                        <AchievementTitle>{a.name}</AchievementTitle>
                                        <AchievementDescription>{a.description}</AchievementDescription>
                                    </AchievementBody>
                                </AchievementContent>
                            </Achievement>
                        ),
                    });

                    //@ts-ignore
                    window.gtag('event', `achievement (${a.name})`, {
                        event_category: a.condition.conditionType,
                        event_label: a.name,
                    });
                });

                setCompletedEntries([
                    ...completedEntries,
                    ...newlyCompletedAchievements.map((a) => ({ date: a.dateCompleted, id: a.id })),
                ]);
            }
        }
    }, [
        isTodaysStatsLogged,
        setCompletedEntries,
        completedEntries,
        incomplete,
        stats,
        toast,
        isAchievementSetsLoaded,
        toastPosition,
    ]);

    return (
        <AchievementContext.Provider
            value={{ achievementPoints, completed: completed ?? [], incomplete: incomplete ?? [] }}
        >
            {children}
        </AchievementContext.Provider>
    );
};

export const useAchievements = () => useContext(AchievementContext);
