import {
    Modal,
    Button,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    Box,
    Divider,
    ModalProps,
} from '@chakra-ui/react';
import React, { VFC } from 'react';

import { useStatsProvider } from '../../../providers/stats-provider';
import { StatsChart } from '../../charts/stats-chart';

export const StatsModal = ({ isOpen, onClose }: Omit<ModalProps, 'children'>) => {
    const { stats } = useStatsProvider();

    if (!stats) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={() => {}}>
            <ModalOverlay />
            <ModalContent bg="#121212" textAlign="center">
                <ModalHeader>Stats</ModalHeader>

                <ModalBody bg="">
                    <Stack>
                        <Text>
                            Total games played: <b>{stats.total}</b>
                        </Text>
                        <Text>
                            Total games won: <b>{stats.won}</b>
                        </Text>
                        <Text>
                            Total games lost: <b>{stats.failed}</b>
                        </Text>
                        <StatsChart />

                        <Box>
                            <Divider mt="8" />
                        </Box>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
