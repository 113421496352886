import { FC } from 'react';
import { AchievementProvider } from './achievement-provider';

import { GameProvider } from './game-provider';
import { ModalProvider } from './modal-provider';
import { SeedProvider } from './seed-provider';
import { StatsProvider } from './stats-provider';

type BlurdleProviderProps = {
    seed: string;
    correctAnswer: string;
    blurdleNumber: number;
};

export const BlurdleProvider: FC<React.PropsWithChildren & BlurdleProviderProps> = ({
    children,
    seed,
    correctAnswer,
    blurdleNumber,
}) => {
    return (
        <SeedProvider seed={seed}>
            <StatsProvider>
                <GameProvider blurdleNumber={blurdleNumber} correctAnswer={correctAnswer}>
                    <AchievementProvider>
                        <ModalProvider>{children}</ModalProvider>
                    </AchievementProvider>
                </GameProvider>
            </StatsProvider>
        </SeedProvider>
    );
};
