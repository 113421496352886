import { useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { getUTC } from '../../utils/time';

export const useCountdown = () => {
    const [timeLeft, setTimeLeft] = useState(1);

    const updateTimeLeft = useCallback(() => {
        const nextMidnight = getUTC().set('hour', 23).set('minute', 59).set('seconds', 59).set('milliseconds', 0);
        const now = getUTC();

        const remainingTimeInSeconds = (nextMidnight.toDate().getTime() - now.toDate().getTime()) / 1000;

        setTimeLeft(remainingTimeInSeconds);
    }, []);

    useEffect(() => {
        updateTimeLeft();

        const interval = setInterval(() => updateTimeLeft(), 1000);

        return () => {
            clearInterval(interval);
        };
    }, [updateTimeLeft]);

    return useMemo(() => {
        const hours = Math.floor(timeLeft / 3600);
        let rest = timeLeft - hours * 3600;
        const minutes = Math.floor(rest / 60);
        rest = rest - minutes * 60;
        const seconds = Math.floor(rest);

        return {
            hours: ('0' + hours).slice(-2),
            minutes: ('0' + minutes).slice(-2),
            seconds: ('0' + seconds).slice(-2),
        };
    }, [timeLeft]);
};
