import {
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Box,
  ModalProps,
  Divider,
} from "@chakra-ui/react";
import React, { VFC } from "react";

import { useGameProvider } from "../../../providers/game-provider";
import { useModalProvider } from "../../../providers/modal-provider";
import { Countdown } from "../../countdown/Countdown";

export const FailedModal = ({ isOpen, onClose }: Omit<ModalProps, 'children'>) => {
    const { openModal } = useModalProvider();
    const { correctAnswer } = useGameProvider();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="#121212" textAlign="center">
                <ModalHeader>Failed!</ModalHeader>

                <ModalBody>
                    <Stack>
                        <Text>
                            Unlucky, you {`didn't`} manage to solve {`today's`} Blurdle. The answer was{' '}
                            <b>{correctAnswer}</b>.
                        </Text>
                        <Box>
                            <Divider mt="4" />
                        </Box>
                    </Stack>

                    <Countdown mt="4" />
                    <Divider my="4" />

                    <Stack>
                        <Button onClick={() => openModal('stats')}>Stats</Button>
                        <Button onClick={() => openModal('achievements')}>Achievements</Button>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
