import { useEffect, useState } from 'react';
import { useGameProvider } from '../../providers/game-provider';
import { getUTC } from '../../utils/time';

const getSuccessEmoji = (incorrectGuesses: number) => {
    let emoji = '🖼️';

    for (let i = 0; i < incorrectGuesses; i++) {
        emoji = emoji + '🟥';
    }

    emoji = emoji + '🟩';

    return emoji;
};

export const useShareProgress = () => {
    const [isShareAvailable, setIsShareAvailable] = useState(false);
    const { incorrectGuesses, blurdleNumber } = useGameProvider();

    useEffect(() => {
        if (!!navigator.share) {
            setIsShareAvailable(true);
        }
    }, []);

    const share = () => {
        navigator.share({
            text: `Blurdle #${blurdleNumber} - ${getSuccessEmoji(incorrectGuesses.length)} | blurdle.adammyoung.com`,
        });
    };

    return { isShareAvailable, share };
};
