import { Stack, Spacer, Flex, Button, Tag, Box, useBreakpointValue } from '@chakra-ui/react';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import React, { VFC } from 'react';
import Select from 'react-select';

import { BlurBox } from '../components/blur-box';
import { Layout } from '../components/layout';

import assetData from '../data/assets.json';
import { Group, useAnswerSubmission } from '../hooks/use-answer-submission';
import { BlurdleProvider } from '../providers';
import { useGameProvider } from '../providers/game-provider';
import { useModalProvider } from '../providers/modal-provider';
import { getRandomNumberWithSeed, prependZeros } from '../utils/math';
import { getUTC } from '../utils/time';

type HomeProps = {
    seed: string;
    blurdleNumber: number;
    imagePath: string;
    correctAnswer: string;
    possibleAnswers: BlurdleAsset[];
};

export type BlurdleAsset = {
    name: string;
    category: string;
};

const formatGroupLabel = (data: Group) => (
    <Box id={data.label} mb="2" display="flex" color="blue.500" alignItems="center" justifyContent="space-between">
        <Box as="span">{data.label}</Box>
        <Box as="span" py="1" px="2" textAlign="center" borderRadius="full" color="white" bg="blue.300">
            {data.options.length}
        </Box>
    </Box>
);

const Home: NextPage<HomeProps> = (props) => {
    const { correctAnswer, blurdleNumber, seed } = props;

    return (
        <BlurdleProvider blurdleNumber={blurdleNumber} correctAnswer={correctAnswer} seed={seed}>
            <Layout>
                <Head>
                    <title>Blurdle #{blurdleNumber}</title>
                    <meta
                        name="description"
                        content="Get 5 chances to guess what the blurry image might be. It's like Wordle, but for pictures!"
                    />
                    <meta property="og:url" content="https://blurdle.adammyoung.com/" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Blurdle" />
                    <meta
                        property="og:description"
                        content="Get 5 chances to guess what the blurry image might be. It's like Wordle, but for pictures!"
                    />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="blurdle.adammyoung.com" />
                    <meta property="twitter:url" content="https://blurdle.adammyoung.com/" />
                    <meta name="twitter:title" content="Blurdle" />
                    <meta
                        name="twitter:description"
                        content="Get 5 chances to guess what the blurry image might be. It's like Wordle, but for pictures!"
                    />
                </Head>
                <HomeContent {...props} />
            </Layout>
        </BlurdleProvider>
    );
};

const HomeContent = ({ possibleAnswers, imagePath, correctAnswer }: HomeProps) => {
    const isSearchEnabled = useBreakpointValue([false, null, true]);
    const { incorrectGuesses, isSolved, isFailed, isFinished } = useGameProvider();
    const { openModal } = useModalProvider();
    const { options, selectedAnswer, setSelectedAnswer, submitAnswer } = useAnswerSubmission(possibleAnswers);

    const handleSubmit = () => {
        if (!isFinished) {
            submitAnswer();
        }

        if (isSolved) {
            openModal('success');
        }

        if (isFailed) {
            openModal('failed');
        }
    };

    return (
        <Flex w="full" py="4" flexDirection="column" h="full">
            <BlurBox textAlign="center" p="8">
                <Box pos="relative" height="400" width="400" sx={{ img: { borderRadius: 'md' } }}>
                    <Image
                        fill
                        draggable="false"
                        alt=""
                        objectFit="contain"
                        src={imagePath}
                        sizes="(max-width: 1024px) 90vw, 50vw"
                    />
                </Box>
            </BlurBox>

            <Spacer />

            <Stack>
                <Flex gap="2" flexWrap="wrap">
                    {incorrectGuesses?.map((guess) => (
                        <Tag key={guess} colorScheme="red">
                            {guess}
                        </Tag>
                    ))}
                    {isSolved && <Tag colorScheme="green">{correctAnswer}</Tag>}
                </Flex>

                <Flex w="full" gap="4" sx={{ '.control': { width: 'full', color: 'black' } }}>
                    {!isFinished && (
                        <Select
                            isSearchable={!!isSearchEnabled}
                            className="control"
                            menuPosition="fixed"
                            defaultValue={selectedAnswer}
                            onChange={setSelectedAnswer}
                            value={selectedAnswer}
                            options={options}
                            isDisabled={isFinished}
                            formatGroupLabel={formatGroupLabel}
                        />
                    )}
                    <Button colorScheme="green" w={!isFinished ? 'auto' : 'full'} onClick={handleSubmit}>
                        {isSolved ? '🎉' : isFailed ? '😔' : 'Submit'}
                    </Button>
                </Flex>
            </Stack>
        </Flex>
    );
};

export default Home;

export const getServerSideProps: GetServerSideProps<HomeProps> = async () => {
    const blurdleNumber = getUTC().diff('2022-05-20', 'days');
    const seed = getUTC().format('YYYY-MM-DD');

    const numberOfCategories = assetData.length;

    const selectedCategoryPosition = getRandomNumberWithSeed(seed, numberOfCategories - 1);
    const selectedCategory = assetData[selectedCategoryPosition];

    const selectedAssetPosition = getRandomNumberWithSeed(seed, selectedCategory.count - 1);
    const selectedAsset = prependZeros(selectedAssetPosition === 0 ? 1 : selectedAssetPosition, 4);

    const path = `${selectedCategory.filepath}/image_${selectedAsset}.jpg`;

    return {
        props: {
            seed,
            blurdleNumber,
            imagePath: path,
            correctAnswer: selectedCategory.name,
            possibleAnswers: assetData.map((a) => ({
                name: a.name,
                category: a.category,
            })),
        },
    };
};
