import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import { Step, useGameProvider } from '../../providers/game-provider';

const blurStepMapping: Record<Step, string> = {
    0: '0rem',
    1: '0.1rem',
    2: '0.2rem',
    3: '0.4rem',
    4: '0.6rem',
    5: '0.8rem',
    6: '1rem',
};

export const BlurBox: FC<React.PropsWithChildren & BoxProps> = ({ children, ...rest }) => {
    const { stepsLeft, isFailed, isSolved } = useGameProvider();

    const filter = !isFailed && !isSolved ? `blur(${blurStepMapping[stepsLeft]})` : '';

    return (
        <Box position="relative">
            <Box
                draggable="false"
                userSelect="none"
                filter={filter}
                _after={{
                    content: '" "',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 99,
                }}
                {...rest}
            >
                {children}
            </Box>
        </Box>
    );
};
