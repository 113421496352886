import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getNextMidnight = () => {
    const utc = getUTC();
    const date = utc.hour(24).minute(0).second(0).millisecond(0);

    return date;
};

export const getUTC = () => {
    return dayjs.utc();
};

export const getDateRange = (startDate: Date, stopDate: Date) => {
    var dateArray = new Array<Date>();
    var currentDate = dayjs(startDate);

    while (currentDate.isSame(stopDate, 'day') || currentDate.isBefore(stopDate, 'day')) {
        dateArray.push(currentDate.toDate());
        currentDate = currentDate.add(1, 'day');
    }

    return dateArray;
};
